.ql-container h1, .ql-container h2, .ql-container h3, .ql-container h4 {
  color: black;
  margin: 10px;
}
.ql-container p {
  color: black;
  margin: 8px;
}
.ql-container ul {
  color: black;
}
