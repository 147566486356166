.customLook{
    --tag-bg                  : #0052BF;
    --tag-hover               : #CE0078;
    --tag-text-color          : #FFF;
    --tags-border-color       : silver;
    --tag-border-radius       : 25px;
    --tag-text-color--edit    : #111;
    --tag-remove-bg           : var(--tag-hover);
    --tag-remove-btn-color    : white;
    --tag-pad                 : .6em 1em;
    --tag-inset-shadow-size   : 1.35em;
    --tag-remove-btn-bg--hover: black;

    display: inline-block;
    min-width: 0;
    border: none;
}

.customLook .tagify__tag{
    margin-top: 0;
}

/* Do not show the "remove tag" (x) button when only a single tag remains */
.customLook .tagify__tag:only-of-type .tagify__tag__removeBtn{
    display: none;
}

.customLook :not(.tagify__tag--editable) .tagify__tag__removeBtn{
    opacity: 0;
    transform: translateX(-6px) scale(.5);
    margin-left: -3ch;
    transition: .12s;
}

.customLook .tagify__tag:hover .tagify__tag__removeBtn{
    transform: none;
    opacity: 1;
    margin-left: -1ch;
}

.customLook + button{
    color: #0052BF;
    font: bold 1.4em/1.65 Arial;
    border: 0;
    background: none;
    box-shadow: 0 0 0 2px inset currentColor;
    border-radius: 50%;
    width: 1.65em;
    height: 1.65em;
    cursor: pointer;
    outline: none;
    transition: .1s ease-out;
    margin: 0 0 0 5px;
    vertical-align: top;
}

.customLook + button:hover{
    box-shadow: 0 0 0 5px inset currentColor;
}

.customLook .tagify__input{
    display: none;
}

body .tagify {
    --tag-bg: #319795;
    --tag-text-color: white;
    --tag-text-color--edit: var(--tag-bg);
    --tag-hover: var(--tag-bg);
    --tag-remove-btn-color: white;
    --tag-border-radius: 10px;
    --tag-remove-bg: #319795;
    --tags-border-color: transparent;
    --tags-hover-border-color: transparent;
    --tagify-dd-bg-color: black;
    --tagify-dd-color-primary: red;
}

.tagify__tag > div {
    box-shadow: 0 0 0 3em var(--tag-hover) inset;
}
.tagify__tag > x {
    box-shadow: 0 0 0 3em var(--tag-hover) inset;
}

.tags-input {
    border-radius: 6px;
    border: 1px solid;
    box-shadow: var(--joy-shadowRing, 0 0 #000),0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08));
    border-color: var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)));
    height: 300px;
}

.tagify__input {
    margin: 3px;
}

.tagify__dropdown--whitelist {
    /*max-width: none !important;*/
}

.tagify__dropdown__wrapper {
    background: #222;
    color: white;
    border: 0;
    outline: #319795;
    inset: 0;
    border-radius: 8px;
}

.tagify__dropdown__item--active {
    background: #319795;
}
